import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnimateOnScroll } from '../hooks/useAnimateOnScroll';

export const Features = (props) => {
  const { t } = useTranslation();
  const featuresRef = useRef(null);

  useAnimateOnScroll(featuresRef, props.data);

  return (
    <div id="features" className="text-center" ref={featuresRef}>
      <div className="container">
        <div className="row">
          <div
            className="col-md-10 col-md-offset-1 section-title animate-on-scroll"
            data-animation="animate-fadeInDown"
          >
            <h2>{t('features.mainTitle')}</h2>
          </div>
        </div>
        <div className="row features-container ">
          {props.data
            ? props.data.map((d) => (
                <div
                  key={d.id}
                  className="col-xs-6 col-md-3 animate-on-scroll"
                  data-animation="animate-fadeInUp"
                >
                  <img
                    className="animate-on-scroll"
                    src={`img/features-icons/${d.id}.svg`}
                    alt="SVG Icon"
                    width="48"
                    height="48"
                  />
                  <h3 className="animate-on-scroll">{t(`features.${d.id}.title`)}</h3>
                  <p className="animate-on-scroll">{t(`features.${d.id}.description`)}</p>
                </div>
              ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  );
};
