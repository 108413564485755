import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnimateOnScroll } from '../hooks/useAnimateOnScroll';

export const Header = () => {
  const { t } = useTranslation();
  const headerRef = useRef(null);

  useAnimateOnScroll(headerRef);

  return (
    <header id="header" ref={headerRef}>
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-8 intro-text">
                <h1 className="animate-on-scroll" data-animation="animate-fadeInDown">
                  {t('landing.mainTitle')}
                  <span></span>
                </h1>
                <p className="animate-on-scroll" data-animation="animate-fadeInUp">
                  {t('landing.subheading')}
                </p>
                <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll animate-on-scroll"
                  data-animation="animate-fadeInUp"
                >
                  {t('landing.learnMore')}
                </a>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
