import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';
import { useAnimateOnScroll } from '../hooks/useAnimateOnScroll';

const initialState = {
  name: '',
  email: '',
  message: '',
};

export const Contact = () => {
  const { t } = useTranslation();
  const [{ name, email, message }, setState] = useState(initialState);
  const contactRef = useRef(null);

  useAnimateOnScroll(contactRef);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_EMAILJS_USER_ID,
      )
      .then(
        () => {
          clearState();
        },
        (error) => {
          console.log(error.text);
        },
      );
  };
  return (
    <div ref={contactRef}>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title animate-on-scroll" data-animation="animate-fadeInDown">
                <h2>{t('contact.mainTitle')}</h2>
                <p>{t('contact.description')}</p>
              </div>
              <form
                name="sentMessage"
                className="animate-on-scroll"
                validate="true"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-md-6 animate-on-scroll" data-animation="animate-fadeInLeft">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder={t('contact.formPlaceholder.name')}
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6 animate-on-scroll" data-animation="animate-fadeInRight">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder={t('contact.formPlaceholder.email')}
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group animate-on-scroll" data-animation="animate-fadeInUp">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder={t('contact.formPlaceholder.message')}
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button
                  type="submit"
                  className="btn btn-custom btn-lg animate-on-scroll"
                  data-animation="animate-fadeInUp"
                >
                  {t('contact.sendMessage')}
                </button>
              </form>
            </div>
          </div>
          <div
            className="col-md-3 col-md-offset-1 contact-info animate-on-scroll"
            data-animation="animate-fadeInUp"
          >
            <div className="contact-item">
              <h3>{t('contact.contactInfo')}</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> {t('contact.address.title')}
                </span>
                <span>{t('contact.address.description')}</span>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <a href="tel:+37376070007">
                  <span>
                    <i className="fa fa-phone"></i> {t('contact.phone.title')}
                  </span>{' '}
                  <span>{t('contact.phone.description')}</span>
                </a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <a href="mailto:info@schei.ltd">
                  <span>
                    <i className="fa fa-envelope-o"></i> {t('contact.email.title')}
                  </span>{' '}
                  <span>{t('contact.email.description')}</span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; 2024 Schei Ltd.</p>
        </div>
      </div>
    </div>
  );
};
