import React from 'react';
import { useTranslation } from 'react-i18next';

export const Image = ({ title, largeImage, smallImage }) => {
  const { t } = useTranslation();
  return (
    <div className="portfolio-item">
      <div className="hover-bg">
        {' '}
        <a href={largeImage} title={title} data-lightbox-gallery="gallery1">
          <div className="hover-text">
            <h4>{t(`gallery.imageTitles.${title}`)}</h4>
          </div>
          <img src={smallImage} className="img-responsive" alt={title} />{' '}
        </a>{' '}
      </div>
    </div>
  );
};
