import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnimateOnScroll } from '../hooks/useAnimateOnScroll';

export const Services = (props) => {
  const { t } = useTranslation();
  const servicesRef = useRef(null);

  useAnimateOnScroll(servicesRef, props.data);

  return (
    <div id="services" className="text-center" ref={servicesRef}>
      <div className="container">
        <div className="section-title animate-on-scroll" data-animation="animate-fadeInDown">
          <h2 className="animate-on-scroll">{t('ourServices.mainTitle')}</h2>
          <p className="animate-on-scroll">{t('ourServices.description')}</p>
        </div>
        <div className="row services-container">
          {props.data
            ? props.data.map((d) => (
                <div
                  key={d.id}
                  className="col-md-4 animate-on-scroll"
                  data-animation="animate-fadeInUp"
                >
                  <img
                    className="animate-on-scroll"
                    src={`img/services-icons/${d.id}.svg`}
                    alt="SVG Icon"
                    width="42"
                    height="42"
                  />
                  <div className="service-desc">
                    <h3 className="animate-on-scroll">{t(`ourServices.services.${d.id}.title`)}</h3>
                    <p className="animate-on-scroll">
                      {t(`ourServices.services.${d.id}.description`)}
                    </p>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  );
};
