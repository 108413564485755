import { useEffect } from 'react';

export const useAnimateOnScroll = (ref, data) => {
  useEffect(() => {
    if (!ref.current) return;

    const elements = ref.current.querySelectorAll('.animate-on-scroll');

    if (elements.length === 0) return;

    const options = {
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries, observerInstance) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const el = entry.target;
          const animationClass = el.dataset.animation || 'animate-fadeInUp';

          el.classList.add(animationClass);
          el.classList.remove('animate-on-scroll');
          observerInstance.unobserve(el);
        }
      });
    }, options);

    elements.forEach((el) => observer.observe(el));

    return () => {
      observer.disconnect();
    };
  }, [ref, data]);
};
