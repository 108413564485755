import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnimateOnScroll } from '../hooks/useAnimateOnScroll';

export const About = (props) => {
  const { t } = useTranslation();
  const aboutRef = useRef(null);

  useAnimateOnScroll(aboutRef);

  return (
    <div id="about" ref={aboutRef}>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6 animate-on-scroll" data-animation="animate-fadeInLeft">
            <img src="img/about.jpg" className="img-responsive animate-on-scroll" alt="" />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2 className="animate-on-scroll" data-animation="animate-fadeInDown">
                {t('aboutUs.mainTitle')}
              </h2>
              <p className="animate-on-scroll" data-animation="animate-fadeInUp">
                {t('aboutUs.mainParagraph')}
              </p>
              <h3 className="animate-on-scroll" data-animation="animate-fadeInDown">
                {t('aboutUs.whyChooseUs')}
              </h3>
              <div className="list-style">
                <div
                  className="col-lg-6 col-sm-6 col-xs-12 animate-on-scroll"
                  data-animation="animate-fadeInLeft"
                >
                  <ul>
                    {props.data
                      ? props.data.Why.map((d) => <li key={`${d}`}>{t(`aboutUs.${d}`)}</li>)
                      : 'loading'}
                  </ul>
                </div>
                <div
                  className="col-lg-6 col-sm-6 col-xs-12 animate-on-scroll"
                  data-animation="animate-fadeInRight"
                >
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d) => <li key={`${d}`}>{t(`aboutUs.${d}`)}</li>)
                      : 'loading'}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
